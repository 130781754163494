import React from "react";
import { setDashboardDetails, setLoginDetails, setPermissionDetails } from "../action/actions";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";
import { useState } from "react";
import { Link } from "react-router-dom";
import { decryptData, encryptData, projectLogo, serverLink } from "./url"
import { useEffect } from "react";

const Login = (props) => {
    const [loading, setLoading] = useState(true)
    const [formData, setFormData] = useState({
        Username: "",
        Password: ""
    })
    useEffect(() => {
        props.setOnLoginDetails([]);
    }, [])

    const Login = (e) => {
        e.preventDefault();
        setLoading(true);
        toast.info("Please wait while login you in...")

        const data = {
            username: formData.Username,
            password: encryptData(formData.Password)
        }
        axios.post(`${serverLink}admin/login`, data).then((res) => {
            if (res.data.message === "success") {
                toast.success("Login successful");
                // props.setOnPermissionDetails(res.data.permissionData);
                props.setOnLoginDetails(res.data.userData);
                setTimeout(() => {
                    window.location.href = "/dashboard";
                }, 1000)
            } else {
                setLoading(false);
                toast.error('Wrong  credentials, please try again.')
            }
        }).catch((e) => {
            toast.error('Network Error!, please try again.')
        })
    }


    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 3000);
    }, []);



    return (
        <div>

            <div className="container-fluid nav-section border-0">
                <nav className="navbar navbar-light">
                    <div className="navbar-nav flex-column">
                        <a href="#home" className="nav-item nav-link active"><span className="fa fa-home"></span> Home</a>
                        <a href="#education" className="nav-item nav-link"><span className="far fa-address-card"></span> Education</a>

                        <a href="#skills" className="nav-item nav-link"><span className="fab fa-servicestack"></span> Skills</a>

                        <a href="#services" className="nav-item nav-link"><span className="fa fa-certificate"></span> Service</a>
                        <a href="#portfolio" className="nav-item nav-link"><span className="fa fa-blog"></span> Works</a>
                        <a href="#contact" className="nav-item nav-link"><span className="fas fa-address-book"></span> Contact</a>
                    </div>
                </nav>
            </div>

            <div className="container-fluid" id="home">
                <div className="container">
                    <div className="row g-0">
                        <div className="col-12 col-lg-3">
                            <div className="header-content bg-dark h-100 pt-6 pe-6 pb-6">
                                <div className="text-start d-flex flex-column justify-content-center wow fadeInUp" data-wow-delay="0.3s">
                                    <p className="text-white sub-title">👋 Hello I'm Ahmad</p>
                                    <h1 className="display-6 text-white mb-0"> I am an Engineer, <br /> I am an IT Developer </h1>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-9">
                            <div className="header-img d-flex h-100 pt-6 ps-6 pb-6">
                                <div className="row g-5">
                                    <div className="col-xl-6 wow fadeInUp" data-wow-delay="0.1s">
                                        <div className="bg-light p-4" style={{ borderRadius: "68% 32% 100% 0% / 0% 75% 25% 100%" }} >
                                            <img src="img/ahmad.jpg" className="img-fluid w-100" style={{ borderRadius: "68% 32% 100% 0% / 0% 75% 25% 100%" }} alt="Image" />
                                        </div>
                                    </div>
                                    <div className="col-xl-6 wow fadeInUp" data-wow-delay="0.3s">
                                        <h2 className="mb-4">Environmental Engineer / Software Developer</h2>
                                        <p className="mb-4">I combine my technical background in engineering with programming skills to solve real-life challenges.
                                        </p>
                                        <div className="d-flex align-items-center mb-2">
                                            <i className="fas fa-map-marker-alt text-primary me-3"></i>
                                            <p className="text-dark mb-0">London, United Kingdom</p>
                                        </div>
                                        <div className="d-flex align-items-center mb-2">
                                            <i className="fas fa-envelope text-primary me-3"></i>
                                            <p className="text-dark mb-0">ahmad.idrees.819@gmail.com</p>
                                        </div>
                                        <div className="d-flex align-items-center mb-2">
                                            <i className="fa fa-phone-alt text-primary me-3"></i>
                                            <p className="text-dark mb-0">+447956091388</p>
                                        </div>
                                        <div className="d-flex">
                                            <a className="btn btn-primary btn-sm-square me-3" href="#"><i className="fab fa-facebook-f text-white"></i></a>
                                            <a className="btn btn-primary btn-sm-square me-3" href="#"><i className="fab fa-twitter text-white"></i></a>
                                            <a className="btn btn-primary btn-sm-square me-3" href="#"><i className="fab fa-instagram text-white"></i></a>

                                            <a className="btn btn-primary btn-sm-square me-3" href="https://github.com/ahmadbaba811"><i className="fab fa-github text-white"></i></a>

                                            <a className="btn btn-primary btn-sm-square me-0" href="https://www.linkedin.com/in/idris-ahmad-2682a8151/"><i className="fab fa-linkedin-in text-white"></i></a>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div className="container-fluid" id="education">
                <div className="container">
                    <div className="row g-0">
                        <div className="col-lg-3">
                            <div className="education-header bg-dark h-100 pt-6 pe-6 pb-6">
                                <div className="text-start d-flex flex-column justify-content-center wow fadeInUp" data-wow-delay="0.1s">
                                    <p className="text-white sub-title">Education</p>
                                    <h1 className="display-6 text-white mb-0">My education qualifications and trainings</h1>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-9">
                            <div className="education-content h-100 pt-6 ps-6 pb-6">
                                <div className="row g-4">
                                    <div className="col-12">
                                        <div className="education-item rounded p-4 h-100 wow fadeInUp" data-wow-delay="0.1s">
                                            <div className="d-flex align-items-center mb-3">
                                                <p className="fs-5 mb-0 me-4">Cranfield University, UK</p>

                                            </div>
                                            <h4 className="mb-1">MSc. Environmental Engineering</h4>

                                            <div>
                                                <span className="fa fa-calendar me-1"></span> 2017 - 2019
                                            </div>
                                            <p className="mb-0">
                                                <br />
                                                <b>Relevant coursework:</b> Programming for Environmental Systems, Data Analysis, Environmental risk assessment and management, waste management, circular economy, process emission, pollution prevention and control, land engineering, health and safety, climate change.
                                                <br /><br />
                                                <b>Grade: </b> Distinction
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div className="education-item rounded p-4 h-100 wow fadeInUp" data-wow-delay="0.1s">
                                            <div className="d-flex align-items-center mb-3">
                                                <p className="fs-5 mb-0 me-4">Ahmadu Bello University, Zaria</p>

                                            </div>
                                            <h4 className="mb-1">BEng. Water and Environmental Engineering</h4>

                                            <div>
                                                <span className="fa fa-calendar me-1"></span> 2017 - 2019
                                            </div>
                                            <p className="mb-0">
                                                <br />
                                                <b>Relevant coursework:</b> Introduction to Computing, Mathematics and Statistics, Programming for Engineers, Engineering mathematics and statistics, hydraulics and hydrology, EIA, Computer-aided engineering design.
                                                <br /><br />
                                                <b>Grade: </b> Graduated with a high 2:1 (4.45/5.0) in the top 5% and achieved a 5.00 GPA in 4th and 5th year
                                            </p>
                                        </div>
                                    </div>


                                    <div className="col-12">
                                        <div className="education-item rounded p-4 h-100 wow fadeInUp" data-wow-delay="0.3s">
                                            <h4 className="mb-3">Trainings and Certifications</h4>

                                            <div className="mb-3">
                                                <h6 className="mb-1">Engineering Project Management (Coursera - Rice University)</h6>

                                                <span className="fa fa-calendar me-1"></span> August 2024
                                            </div>

                                            <div className="mb-3">
                                                <h6 className="mb-1">NEBOSH International Certificate in Occupational Health and Safety</h6>
                                                <span className="fa fa-calendar me-1"></span> December 2024
                                            </div>
                                            <div className="mb-3">
                                                <h6 className="mb-1">IHRDC Safety Basics Course</h6>
                                                <span className="fa fa-calendar me-1"></span> December 2024
                                            </div>

                                            <div className="mb-3">
                                                <h6 className="mb-1">Learning Data Analytics - LinkedIn Learning</h6>
                                                <span className="fa fa-calendar me-1"></span> February 2022
                                            </div>

                                            <div className="mb-3">
                                                <h6 className="mb-1">Climate Adaptation Training (UNFCCC - IHE Delft, Netherlands)</h6>
                                                <span className="fa fa-calendar me-1"></span> December 2021
                                            </div>

                                            <div className="mb-3">
                                                <h6 className="mb-1">IBM Interpersonal Skills Course</h6>
                                                <span className="fa fa-calendar me-1"></span> May 2020
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid" id="skills">
                <div className="container">
                    <div className="row g-0">
                        <div className="col-lg-3">
                            <div className="skills-header h-100 bg-dark pt-6 pe-6 pb-6">
                                <div className="text-start d-flex flex-column justify-content-center wow fadeInUp" data-wow-delay="0.1s">
                                    <p className="text-white sub-title">Skills</p>
                                    <h1 className="display-6 text-white mb-0">My expertise skills</h1>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-9">
                            <div className="skills-content h-100 pt-6 ps-6 pb-6">
                                <div className="row g-4">
                                    <div className="col-6 col-md-4 col-lg-4 col-xl-3">
                                        <div className="skills-item text-center rounded p-4 h-100 wow fadeInUp" data-wow-delay="0.1s">
                                            <div className="text-primary text-center mb-3"><span className="fab fa- fa-3x"></span></div>
                                            <div className="counter-counting d-flex justify-content-center">
                                                <h4 className="display-6" data-toggle="counter-up">99</h4>
                                                <h4 className="display-6">%</h4>
                                            </div>
                                            <p className="mb-0"><b>MS office</b></p>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 col-lg-4 col-xl-3">
                                        <div className="skills-item text-center rounded p-4 h-100 wow fadeInUp" data-wow-delay="0.1s">
                                            <div className="text-primary text-center mb-3"><span className="fab fa- fa-3x"></span></div>
                                            <div className="counter-counting d-flex justify-content-center">
                                                <h4 className="display-6" data-toggle="counter-up">95</h4>
                                                <h4 className="display-6">%</h4>
                                            </div>
                                            <p className="mb-0"><b>.NET C#</b></p>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 col-lg-4 col-xl-3">
                                        <div className="skills-item text-center rounded p-4 h-100 wow fadeInUp" data-wow-delay="0.3s">
                                            <div className="text-primary text-center mb-3"><span className="fab fa- fa-3x"></span></div>
                                            <div className="counter-counting d-flex justify-content-center">
                                                <h4 className="display-6" data-toggle="counter-up">95</h4>
                                                <h4 className="display-6">%</h4>
                                            </div>
                                            <p className="mb-0"><b>Javascript</b></p>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 col-lg-4 col-xl-3">
                                        <div className="skills-item text-center rounded p-4 h-100 wow fadeInUp" data-wow-delay="0.5s">
                                            <div className="text-primary text-center mb-3"><span className="fab fa- fa-3x"></span></div>
                                            <div className="counter-counting d-flex justify-content-center">
                                                <h4 className="display-6" data-toggle="counter-up">70</h4>
                                                <h4 className="display-6">%</h4>
                                            </div>
                                            <p className="mb-0"><b>AWS</b></p>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 col-lg-4 col-xl-3">
                                        <div className="skills-item text-center rounded p-4 h-100 wow fadeInUp" data-wow-delay="0.7s">
                                            <div className="text-primary text-center mb-3"><span className="fab fa- fa-3x"></span></div>
                                            <div className="counter-counting d-flex justify-content-center">
                                                <h4 className="display-6" data-toggle="counter-up">75</h4>
                                                <h4 className="display-6">%</h4>
                                            </div>
                                            <p className="mb-0"><b>Git</b></p>
                                        </div>
                                    </div>

                                    <div className="col-6 col-md-4 col-lg-4 col-xl-3">
                                        <div className="skills-item text-center rounded p-4 h-100 wow fadeInUp" data-wow-delay="0.3s">
                                            <div className="text-primary text-center mb-3"><span className="fab fa- fa-3x"></span></div>
                                            <div className="counter-counting d-flex justify-content-center">
                                                <h4 className="display-6" data-toggle="counter-up">98</h4>
                                                <h4 className="display-6">%</h4>
                                            </div>
                                            <p className="mb-0"><b>HTML & CSS</b></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="container-fluid" id="services">
                <div className="container">
                    <div className="row g-0">
                        <div className="col-lg-3">
                            <div className="service-header h-100 bg-dark pt-6 pe-6 pb-6">
                                <div className="text-start d-flex flex-column justify-content-center wow fadeInUp" data-wow-delay="0.1s">
                                    <p className="text-white sub-title">My Services</p>
                                    <h1 className="display-6 text-white mb-0">What I can do for you</h1>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-9">
                            <div className="service-content h-100 pt-6 ps-6 pb-6">
                                <div className="row g-4">
                                    <div className="col-12">
                                        <div className="service-item p-4 wow fadeInUp" data-wow-delay="0.1s">
                                            <div className="row g-4 align-items-center">
                                                <div className="col-xl-2">
                                                    <i className="fas fa-leaf fa-3x" style={{ color: "green" }}></i>
                                                </div>
                                                <div className="col-xl-3">
                                                    <a href="#" className="h4 mb-0">Environmental Consultancy</a>
                                                </div>
                                                <div className="col-xl-7">
                                                    <p>
                                                        <i className="fa fa-check me-2" style={{ color: "green" }}>
                                                        </i> Environmental Impact Assessments (EIA)
                                                    </p>
                                                    <p>
                                                        <i className="fa fa-check me-2" style={{ color: "green" }}>
                                                        </i> Environmental Economics
                                                    </p>
                                                    <p>
                                                        <i className="fa fa-check me-2" style={{ color: "green" }}>
                                                        </i> Energy and GHG Analysis
                                                    </p>
                                                    <p>
                                                        <i className="fa fa-check me-2" style={{ color: "green" }}>
                                                        </i> Air Pollution Modelling and anlysis                                                    </p>
                                                    <p>
                                                        <i className="fa fa-check me-2" style={{ color: "green" }}>
                                                        </i> Waste Management Solutions

                                                    </p>
                                                    <p>
                                                        <i className="fa fa-check me-2" style={{ color: "green" }}>
                                                        </i> Research and Development
                                                    </p>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="service-item p-4 wow fadeInUp" data-wow-delay="0.3s">
                                            <div className="row g-4 align-items-center">
                                                <div className="col-xl-2">
                                                    <i className="fas fa-laptop-code fa-3x" style={{ color: "#002D62" }}></i>
                                                </div>
                                                <div className="col-xl-3">
                                                    <a href="#" className="h4 mb-0">Web Development</a>
                                                </div>
                                                <div className="col-xl-7">
                                                    <p>
                                                        <i className="fa fa-check me-2" style={{ color: "#002D62" }} ></i>
                                                        Website Design
                                                    </p>

                                                    <p ><i className="fa fa-check me-2" style={{ color: "#002D62" }}></i>
                                                        Web Application Development</p>

                                                    <p>
                                                        <i className="fa fa-check me-2" style={{ color: "#002D62" }}></i>
                                                        Mobile App. Development
                                                    </p>
                                                    <p>
                                                        <i className="fa fa-check me-2" style={{ color: "#002D62" }} ></i>
                                                        System Analysis and Project Management
                                                    </p>

                                                    <p >
                                                        <i className="fa fa-check me-2" style={{ color: "#002D62" }}></i>
                                                        Cloud Management</p>

                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="col-12">
                                        <div className="service-item p-4 wow fadeInUp" data-wow-delay="0.5s">
                                            <div className="row g-4 align-items-center">
                                                <div className="col-xl-2">
                                                    <i className="fas fa-database fa-3x" style={{ color: "darkgrey" }}></i>
                                                </div>
                                                <div className="col-xl-3">
                                                    <a href="#" className="h4 mb-0">Database Design</a>
                                                </div>

                                                <div className="col-xl-7">
                                                    <p><i className="fa fa-check me-2" style={{ color: "darkgrey" }}></i> Database Architecture</p>
                                                    <p><i className="fa fa-check me-2" style={{ color: "darkgrey" }}></i> Data Migration & Integration</p>
                                                    <p ><i className="fa fa-check me-2" style={{ color: "darkgrey" }}></i> Data Analytics & Reporting</p>
                                                    <p ><i className="fa fa-check me-2" style={{ color: "darkgrey" }}></i> Data Security & Compliance</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="container-fluid" id="portfolio">
                <div className="container">
                    <div className="row g-0">
                        <div className="col-lg-3">
                            <div className="portfolio-header h-100 bg-dark pt-6 pe-6 pb-6">
                                <div className="text-start d-flex flex-column justify-content-center wow fadeInUp" data-wow-delay="0.1s">
                                    <p className="text-white sub-title">Portfolio</p>
                                    <h1 className="display-6 text-white mb-0">My recent works</h1>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-9">
                            <div className="portfolio-content h-100 pt-1 ps-6 pb-1">
                                <div className="portfolio-item py-3 border-bottom wow fadeInUp" data-wow-delay="0.1s">
                                    <div className="row g-4 align-items-center">
                                        <div className="col-xl-8">
                                            <h4 className="text-body">Environmental Pollution</h4>
                                            <h4 className="mb-0">
                                                <a href="https://codeflex.com.ng/assets/air_polution_v_2.pdf" style={{ color: "#002D62" }} target="__blank" > Air Pollution Abatement from Green Infrastructure - Desk and Analytical Study</a>
                                            </h4>
                                        </div>
                                        <div className="col-9 col-xl-4">
                                            <div className="portfolio-img">
                                                <div className="portfolio-img-inner"
                                                    style={{ visibility: "visible" }}>
                                                    <img src="img/c-portfolio-2.jpeg" alt="Image"
                                                        style={{ Height: "10px", Width: "20px" }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="portfolio-item py-3 border-bottom wow fadeInUp" data-wow-delay="0.1s">
                                    <div className="row g-4 align-items-center">
                                        <div className="col-xl-8">
                                            <h4 className="text-body">Web Development</h4>

                                            <h4 className="mb-0">
                                                <a href="http://ahmadbaba-001-site1.ptempurl.com/" target="__blank" style={{ color: "#002D62" }}>
                                                    Farmers Information Management System
                                                </a>
                                            </h4>
                                        </div>
                                        <div className="col-9 col-xl-4">
                                            <div className="portfolio-img">
                                                <div className="portfolio-img-inner"
                                                    style={{ visibility: "visible" }}>
                                                    <img src="img/Portfolio-1.jpg" alt="Image"
                                                        style={{ Height: "10px", Width: "20px" }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>



                                <div className="portfolio-item py-5 border-bottom wow fadeInUp" data-wow-delay="0.3s">
                                    <div className="row g-4 align-items-center">
                                        <div className="col-xl-8">
                                            <h4 className="text-body">Web Development</h4>
                                            <h4 className="mb-0">
                                                <a href="http://shuraim81-001-site1.jtempurl.com/" target="__blank" style={{ color: "#002D62" }}>
                                                    School Information Management System
                                                </a>
                                            </h4>
                                        </div>
                                        <div className="col-9 col-xl-4">
                                            <div className="portfolio-img">
                                                <div className="portfolio-img-inner"
                                                    style={{ visibility: "visible" }}>
                                                    <img src="img/portfolio-2.jpg" alt="Image"
                                                        style={{ Height: "10px", Width: "20px" }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="portfolio-item py-5 wow fadeInUp" data-wow-delay="0.5s">
                                    <div className="row g-4 align-items-center">
                                        <div className="col-xl-8">
                                            <h4 className="text-body">Web and Mobile Development</h4>
                                            <h4 className="mb-0">
                                                <a href="https://hitel.teamcoded.com.ng/" target="__blank" style={{ color: "#002D62" }}>
                                                    Multi-service platforms (Utiltiy Payment App - Airtime, Data, Electricity etc.)
                                                </a>
                                            </h4>
                                        </div>
                                        <div className="col-9 col-xl-4">
                                            <div className="portfolio-img">
                                                <div className="portfolio-img-inner"
                                                    style={{ visibility: "visible" }}>
                                                    <img src="img/payment-1.jpg" alt="Image"
                                                        style={{ Height: "10px", Width: "20px" }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="portfolio-item py-5 wow fadeInUp" data-wow-delay="0.5s">
                                    <div className="row g-4 align-items-center">
                                        <div className="col-xl-8">
                                            <h4 className="text-body">Web and Mobile Development</h4>
                                            <h4 className="mb-0">
                                                <a href="https://www.cosmopolitan.edu.ng/" target="__blank" style={{ color: "#002D62" }}>
                                                    Learning Management System (Nigerian Universities System)
                                                </a>
                                            </h4>
                                        </div>
                                        <div className="col-9 col-xl-4">
                                            <div className="portfolio-img">
                                                <div className="portfolio-img-inner"
                                                    style={{ visibility: "visible" }}>
                                                    <img src="img/schools-1.jpg" alt="Image"
                                                        style={{ Height: "10px", Width: "20px" }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="portfolio-item py-5 wow fadeInUp" data-wow-delay="0.5s">
                                    <div className="row g-4 align-items-center">
                                        <div className="col-xl-8">
                                            <h4 className="text-body">Web and Mobile Development</h4>
                                            <h4 className="mb-0">
                                                <a href="https://zkt.codeflex.com.ng/" target="__blank" style={{ color: "#002D62" }}>
                                                    Group Savings App (Asusu)                                                </a>
                                            </h4>
                                        </div>
                                        <div className="col-9 col-xl-4">
                                            <div className="portfolio-img">
                                                <div className="portfolio-img-inner"
                                                    style={{ visibility: "visible" }}>
                                                    <img src="img/asusu-1.jpg" alt="Image"
                                                        style={{ Height: "10px", Width: "20px" }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="portfolio-item py-5 wow fadeInUp" data-wow-delay="0.5s">
                                    <div className="row g-4 align-items-center">
                                        <div className="col-12 ps-6 pt-2">
                                            <a
                                                href="https://github.com/ahmadbaba811"
                                                target="__blank"
                                                className="btn btn-primary w-100 py-3"
                                            >
                                                Learn more about my projects
                                            </a>

                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Portfolio End */}



            {/* Testimonial Start */}
            <div className="container-fluid">
                <div className="container">
                    <div className="row g-0">
                        <div className="col-lg-3">
                            <div className="testimonial-header h-100 bg-dark pt-6 pe-6 pb-6">
                                <div className="text-start d-flex flex-column justify-content-center wow fadeInUp" data-wow-delay="0.1s">
                                    <p className="text-white sub-title">Curriculum Vitea</p>
                                    <h1 className="display-6 text-white mb-0">Download my CV</h1>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-9">
                            <div className="col-12 ps-6 pt-6">
                                <a
                                    href="https://codeflex.com.ng/assets/ahmad.pdf"
                                    target="__blank"
                                    className="btn w-100 py-3"
                                    style={{
                                        border: "2px solid darkblue",
                                        borderRadius: "4px",
                                        padding: "10px 20px",
                                        backgroundColor: "white",
                                        color: "darkblue",
                                        fontSize: "16px",
                                        fontWeight: "bold",
                                        cursor: "pointer",
                                        transition: "background-color 0.3s, color 0.3s",
                                    }}
                                    onMouseOver={(e) => {
                                        e.target.style.backgroundColor = "#002D62";
                                        e.target.style.color = "white";
                                    }}
                                    onMouseOut={(e) => {
                                        e.target.style.backgroundColor = "white";
                                        e.target.style.color = "darkblue";
                                    }}
                                >
                                    Download My CV
                                </a>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
            {/* Testimonial End */}


            {/* Contact Start */}
            <div className="container-fluid" id="contact">
                <div className="container">
                    <div className="row g-0">
                        <div className="col-lg-3">
                            <div className="contact-header h-100 bg-dark pt-6 pe-6 pb-6">
                                <div className="text-start d-flex flex-column justify-content-center wow fadeInUp" data-wow-delay="0.1s">
                                    <p className="text-white sub-title">Contact Me</p>
                                    <h1 className="display-6 text-white mb-0">Let’s Start A New Project</h1>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-9">
                            <div className="contact-content h-100 pt-6 ps-6 pb-6">
                                <div className="bg-light p-4">
                                    <div className="row g-5">
                                        <div className="col-xl-5 wow fadeInUp" data-wow-delay="0.1s">
                                            <div className="d-flex mb-4">
                                                <div className="btn-xl-square bg-primary text-white me-3">
                                                    <i className="fas fa-map-marker-alt"></i>
                                                </div>
                                                <div>
                                                    <h4>Address</h4>
                                                    <p className="mb-0">London, United Kingdom</p>
                                                </div>
                                            </div>
                                            <div className="d-flex mb-4">
                                                <div className="btn-xl-square bg-primary text-white me-3">
                                                    <i className="fas fa-envelope"></i>
                                                </div>
                                                <div>
                                                    <h4>Mail Us</h4>
                                                    <p className="mb-0">ahmad.idrees.819@gmail.com</p>
                                                </div>
                                            </div>
                                            <div className="d-flex">
                                                <div className="btn-xl-square bg-primary text-white me-3">
                                                    <i className="fa fa-phone-alt"></i>
                                                </div>
                                                <div>
                                                    <h4>Telephone</h4>
                                                    <p className="mb-0">+447956091388</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-7 wow fadeInUp" data-wow-delay="0.3s">
                                            <div>
                                                <form>
                                                    <div className="row g-3">
                                                        <div className="col-lg-12 col-xl-6">
                                                            <div className="form-floating">
                                                                <input type="text" className="form-control border-0" id="name" placeholder="Your Name" />
                                                                <label for="name">Your Name</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-12 col-xl-6">
                                                            <div className="form-floating">
                                                                <input type="email" className="form-control border-0" id="email" placeholder="Your Email" />
                                                                <label for="email">Your Email</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-12 col-xl-6">
                                                            <div className="form-floating">
                                                                <input type="phone" className="form-control border-0" id="phone" placeholder="Phone" />
                                                                <label for="phone">Your Phone</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-12 col-xl-6">
                                                            <div className="form-floating">
                                                                <input type="text" className="form-control border-0" id="project" placeholder="Project" />
                                                                <label for="project">Your Project</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="form-floating">
                                                                <input type="text" className="form-control border-0" id="subject" placeholder="Subject" />
                                                                <label for="subject">Subject</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="form-floating">
                                                                <textarea className="form-control border-0" placeholder="Leave a message here" id="message" style={{ Height: "120px" }}></textarea>
                                                                <label for="message">Message</label>
                                                            </div>

                                                        </div>
                                                        <div className="col-12">
                                                            <button className="btn btn-primary w-100 py-3">Send Message</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <a href="#" className="btn btn-primary btn-lg-square back-to-top"><i className="fa fa-arrow-up"></i></a>



        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setOnLoginDetails: (p) => {
            dispatch(setLoginDetails(p));
        },
        setOnPermissionDetails: (p) => {
            dispatch(setPermissionDetails(p));
        },
        setOnDashboardData: (p) => {
            dispatch(setDashboardDetails(p));
        },
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(Login);
