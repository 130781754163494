import axios from "axios";
const CryptoJS = require("crypto-js");

export const serverStatus = "Dev";

export const serverLink ="http://localhost:5000/";

export const paymentAPIKey = serverStatus === "Dev" ? "122" :  "122" ;
export const shortCode = "HITEL";
export const projectName = "HITEL";
export const projectEmail = "info@hitel.com";
export const projectPhone = "080123456789";
export const projectLogo =  "";
export const projectLogoWhite  = "";

export const bucketUrl =
  "https://storage.googleapis.com/conference-management-363620.appspot.com/";

export const formatDateAndTime = (date, option) => {
  if (date !== null) {
    const user_date = new Date(date);
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const monthNamesShort = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const day =
      user_date.getDate() < 10
        ? "0" + user_date.getDate()
        : user_date.getDate();
    const hour =
      user_date.getHours() < 10
        ? "0" + user_date.getHours()
        : user_date.getHours();
    const min =
      user_date.getMinutes() < 10
        ? "0" + user_date.getMinutes()
        : user_date.getMinutes();
    const sec =
      user_date.getSeconds() < 10
        ? "0" + user_date.getSeconds()
        : user_date.getSeconds();

    let date_string = "";
    if (option === "date_and_time")
      date_string = `${day}-${
        monthNames[user_date.getMonth()]
      }-${user_date.getFullYear()} : ${hour}:${min}:${sec}`;
    else if (option === "time") date_string = `${hour}:${min}`;
    else if (option === "date")
      date_string = `${day}-${
        monthNames[user_date.getMonth()]
      }-${user_date.getFullYear()}`;
    else if (option === "day") date_string = day;
    else if (option === "full_month")
      date_string = monthNames[user_date.getMonth()];
    else if (option === "short_month")
      date_string = monthNamesShort[user_date.getMonth()];
    else if (option === "year_only") date_string = user_date.getFullYear();

    return date_string;
  } else {
    return "--";
  }
};
function checkTime(i) {
  if (i < 10) {
    i = "0" + i;
  }
  return i;
}

export const imageExists = (url) => {
  const http = new XMLHttpRequest();
  http.open('HEAD', url, false);
  http.send();
  return http.status !== 404;
};
export const calculateAge = (birthday) => {
  let getAge = Date.now() - new Date(birthday).getTime();
  let ageDate = new Date(getAge); // milliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
};

export function getDayName(date) {
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const dayOfWeek = date.getDay();
  return daysOfWeek[dayOfWeek];
}

export const generate_token = (length) => {
  //edit the token allowed characters
  let a = "1234567890".split("");
  let b = [];
  for (let i = 0; i < length; i++) {
    let j = (Math.random() * (a.length - 1)).toFixed(0);
    b[i] = a[j];
  }
  return b.join("");
};
export const moneyFormat = (amount) => {
  // Check if the provided amount is a number
  if (typeof amount !== "number") {
    return "Invalid input";
  }

  // Format the number with two decimal places and commas as thousand separators
  return amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
};

export function TimeFormat(time) {
  var today = new Date(time);
  var h = today.getHours();
  var m = today.getMinutes();
  var s = today.getSeconds();
  // add a zero in front of numbers<10
  m = checkTime(m);
  s = checkTime(s);
  const t = h + ":" + m + ":" + s;
  return t;
}

export const formatAMPM = (date) => {
  let hours = date.toString().split(":")[0].trim();
  let minutes = date.toString().split(":")[1].trim();
  const ampm = hours >= "12" ? "pm" : "am";

  hours %= 12;
  hours = parseInt(hours) || 12;
  minutes =
    parseInt(minutes) < 10 ? `0${parseInt(minutes)}` : parseInt(minutes);

  const strTime = `${hours}:${minutes} ${ampm}`;
  return strTime;
};

export const projectCode = "HITEL";

export const formatDate = (date) => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

export function timeConvert(time) {
  // Check correct time format and split into components
  if (time) {
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? "AM" : "PM"; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(""); // return adjusted time or original string
  }
}

export const currencyConverter = (amount) => {
  const formatter = new Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: "NGN",
  });
  return formatter.format(amount);
};

export const getCurrentTime = () => {
  const now = new Date();
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const seconds = String(now.getSeconds()).padStart(2, '0');
  return `${hours}:${minutes}:${seconds}`;
}

export function getDaysDifference(startDateStr, endDateStr) {
  const startDate = new Date(startDateStr);
  const endDate = new Date(endDateStr);
  const startMs = startDate.getTime();
  const endMs = endDate.getTime();
  const differenceMs = endMs - startMs;
  const daysDifference = Math.floor(differenceMs / (1000 * 60 * 60 * 24));
  return daysDifference;
}

export function encryptData(string) {
  let secret_key = projectCode;
  let secret_iv = projectCode;
  // hash
  let kee = CryptoJS.SHA256(secret_key);
  let ivv = CryptoJS.SHA256(secret_iv).toString().substr(0, 16);

  kee = CryptoJS.enc.Utf8.parse(kee.toString().substr(0, 32));
  ivv = CryptoJS.enc.Utf8.parse(ivv);

  let decrypted = CryptoJS.AES.encrypt(string, kee, { iv: ivv });

  let result = decrypted.toString();
  return btoa(result);
}

export function decryptData(string) {
  let secret_key = projectCode;
  let secret_iv = projectCode;
  // hash
  let kee = CryptoJS.SHA256(secret_key);
  let ivv = CryptoJS.SHA256(secret_iv).toString().substr(0, 16);

  kee = CryptoJS.enc.Utf8.parse(kee.toString().substr(0, 32));
  ivv = CryptoJS.enc.Utf8.parse(ivv);

  var decrypted = CryptoJS.AES.decrypt(atob(string), kee, { iv: ivv });

  return decrypted.toString(CryptoJS.enc.Utf8);
}

export const sendMail = (receiver, subject, body) => {
  axios
    .post(`${serverLink}mail/send_mail`, {
      receiver: receiver,
      subject: subject,
      body: body,
    })
    .then((res) => {
      if (res.data.message === "success") {
      } else {
        console.log(res.data);
      }
    });
};
